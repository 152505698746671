jQuery(document).ready(function ($) {

    //This scroll down to the form after clicking a button
    $('a[href^="#form"]').on('click', function (event) {
        var target = $($(this).attr('href'));

        if (target.length) {
            event.preventDefault();
            $('html, body').animate({
                scrollTop: target.offset().top
            }, 900);
        }
    });

    //Additional method validation
    $.validator.addMethod("tel", function (value, element) {
        return this.optional(element) || /^([+]39)?\s?((313)|(32[03789])|(33[013456789])|(34[0256789])|(36[0368])|(37[037])|(38[0389])|(39[0123]))[\s-]?([\d]{7})$/.test(value);
    }, "Specificare un numero di telefono valido senza usare spazi.");

    $.validator.addMethod("emailVal", function (value, elem) {
        return this.optional(elem) || /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);
    }, "Specificare un indirizzo email valido");

    //Validation
    var form = $('#form');

    form.validate({
        lang: 'it',
        rules: {
            name: {
                required: true
            },
            email: {
                required: true,
                emailVal: true
            },
            phone: {
                required: true,
                tel: true
            },
            model: {
                required: true
            },
            brand: {
                required: true
            },
            year: {
                digits: true,
                maxlength: 4,
                minlength: 4
            },
            privacy: {
                required: true
            },
            image: {
                extension: 'jpeg|jpg|png|gif',
                accept: 'image/jpeg,image/jpg,image/png,image/gif',
            }
        },
        messages: {
            image: 'Sono accetate solo le immagini nel formato .jpg, .png, .gif'
        }
    });

    var modal_button = $('#modal_button');
    var validator = form.validate();

    //Check if modal button is clicked
    modal_button.on('click', function () {
        //If the required fields are filled with right content open the modal dialog
        if (validator.element("#brand") && validator.element('#model') && validator.element('#image') && validator.element('#year')) {
            $(this).attr('data-target', '#myModal');
        }
    });

    form.submit(function () {
        //Check if the form is validated
        if ($(this).valid()) {
            //If it is true then disable the submit button after the submission
            $('#submit_button').attr('disabled', true);
        }
    });

    /* Add class on upload container when the input file content changes */
    $('#image').change(function () {
        if ($(this).val()) {
            $(".file-area").addClass('selected');
            $(this).addClass('valid').removeClass('error');
            $('#image-error').remove();
        }
    });
});
